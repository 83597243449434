import {Component, OnDestroy, OnInit} from '@angular/core';
import {FunctionalityService} from '../../shared/services/functionality.service';
import {TranslateService} from '../../core/translations/translate.service';
import {BaseSubNavbarService} from '../../shared/base-components/base-sub-navbar/base-sub-navbar.service';
import {BaseSubNavbarComponentFactoryService} from '../../shared/services/base-sub-navbar-component-factory.service';

@Component({
    selector: 'app-mobile',
    templateUrl: './mobile.component.html',
    standalone: false
})
export class MobileComponent implements OnInit, OnDestroy {

  constructor(private functionalityService: FunctionalityService,
              private translateService: TranslateService,
              private baseSubNavbarService: BaseSubNavbarService,
              private baseSubNavbarComponentFactoryService: BaseSubNavbarComponentFactoryService) {
    this.functionalityService.flushSubFunctionality();
    this.baseSubNavbarComponentFactoryService.attachSubNavbar();
  }

  public ngOnInit(): void {
    this.translateService.loadTranslationModule('mobile');
  }

  public ngOnDestroy(): void {
    this.baseSubNavbarComponentFactoryService.detachBaseSubNavbar();
  }

}
